import Link from 'next/link';
import { memo } from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'next/image';

import styles from '@/styles/Components/FrontContainer/Footer.module.scss';

const FooterLogo = () => {
  return (
    <>
      <Col md="3">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <Link href="/">
              <Image
                src="/assets/img/logo.webp"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                height={50}
                quality={75}
                width={100}
                alt="Best Website for Adult in India"
              />
            </Link>
          </div>
          <div className={styles.footerContent}>
            <p> India's #1 Website for Adult Ads: Your Go-To for Escorts and Call Girls</p>
          </div>
          <div className={styles.footerSocial}>
            <ul className="social-link">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=61550723351361&mibextid=9R9pXO"
                  title="Facebook"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/fb.webp" alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/247torax/status/1700806093759508846?s=46"
                  title="Twitter"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/twitter.webp" alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/247torax?igshid=OGQ5ZDc2ODk2ZA=="
                  title="Instagram"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/insta.webp" alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footerRTA}>
            <svg width="47" height="18" viewBox="0 0 47 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.18 9.64732C11.1769 9.64742 11.1755 9.64698 11.1755 9.64607L11.18 9.64732Z"
                fill="#323232"
              ></path>{' '}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-0.00317383 0.0963287V17.1546H5.18948V10.2293H5.9828C6.45968 10.2293 6.79388 10.4709 7.04412 10.6518L7.0646 10.6667C7.26654 10.8125 7.53338 11.2134 7.64156 11.3956L10.7427 17.1546H16.5845C15.695 15.4172 13.916 11.9788 13.7718 11.7601C13.6437 11.5659 13.0145 10.6302 12.5097 10.2657C12.0946 9.96606 11.4938 9.73338 11.18 9.64732C11.1769 9.64742 11.1755 9.64698 11.1755 9.64607L11.18 9.64732C11.2186 9.64598 11.5126 9.55944 12.4736 9.20868C13.9521 8.66902 14.6012 7.42266 14.9257 6.73012C15.1853 6.17609 15.2142 5.05345 15.2142 4.32446H20.479V17.1546H25.6716V4.25156H32.7394L27.9795 17.1546H33.2442L34.0375 14.3116H40.0235L40.889 17.1546H46.298L39.9514 0.0963287H-0.00317383ZM35.1915 10.6667L37.0666 4.54315L38.9417 10.6667H35.1915ZM5.18948 3.52257V7.02171H7.64156C7.93004 7.02171 8.83155 6.87592 9.33639 6.58432C9.84123 6.29273 10.0215 5.49084 10.0215 5.19924C10.0215 4.90765 9.91335 4.39736 9.44457 3.95996C9.06954 3.61005 8.00216 3.52257 7.64156 3.52257H5.18948Z"
                fill="#323232"
              ></path>
            </svg>
            <span className={styles.RTAText}>RESTRICTED TO ADULTS</span>
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(FooterLogo);
